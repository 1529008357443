.experience-component img {
  max-width: 100%; }

.experience-component[class^='experience-commerce_layouts'], .experience-component[class*=' experience-commerce_layouts'] {
  margin-bottom: 0; }

.experience-component .bg-accent {
  background-color: var(--color-light-gray); }

.image-component {
  margin: 0;
  width: 100%;
  position: relative; }

.common-image-component {
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: var(--focal-point-x) var(--focal-point-y);
     object-position: var(--focal-point-x) var(--focal-point-y); }
  .common-image-component.stretch {
    width: 100%; }

.common-image-filter {
  filter: brightness(40%); }

.image-heading-container {
  position: absolute;
  top: 50%;
  width: 100%; }
  @media (min-width: 544px) {
    .image-heading-container {
      padding-left: 7%; } }

.experience-commerce_layouts-carousel .section-heading {
  margin: 1.375rem 0; }

.carousel.bg-accent {
  padding: 1rem 0; }

.pd-slider .product-tile-pd {
  margin: 0; }

.product-tile-pd .product-tile-body .color-swatches .swatches-ul {
  margin: 0; }

.pd-text-slider .pd-slide {
  display: none; }

.pd-text-slider .slick-slide {
  height: auto; }
  @media (min-width: 769px) {
    .pd-text-slider .slick-slide {
      height: 100%; } }

.pd-text-slider.slick-initialized .pd-slide {
  display: block; }

.pd-text-slider .slick-prev {
  left: 0; }
  @media (min-width: 769px) {
    .pd-text-slider .slick-prev {
      left: 0.625rem; } }

.pd-text-slider .slick-next {
  right: 0; }
  @media (min-width: 769px) {
    .pd-text-slider .slick-next {
      right: 0.625rem; } }

@media (min-width: 769px) {
  .pd-text-slider.slick-dotted.slick-slider {
    margin-bottom: 0; } }

@media (min-width: 769px) {
  .pd-text-slider .slick-dots {
    bottom: 0; } }

.pd-text-slider .slick-dots li {
  margin: 0 0.4375rem; }
  .pd-text-slider .slick-dots li button:before {
    height: 0.8125rem;
    width: 0.8125rem;
    color: #999;
    background-color: #999;
    border: 1px solid #999; }
  .pd-text-slider .slick-dots li.slick-active button:before {
    color: #0070d2;
    background-color: #0070d2;
    border: 1px solid #0070d2; }

.in-editor .carousel.edit-mode .pd-slider {
  display: flex;
  flex-wrap: wrap; }
  .in-editor .carousel.edit-mode .pd-slider .pd-slide {
    display: block; }

.in-editor .carousel.edit-mode .pd-product-slider .pd-slide {
  display: block;
  max-width: 18.75rem;
  float: left; }

.in-editor .carousel.edit-mode .pd-product-slider .product-tile {
  display: block; }

.in-editor .carousel.edit-mode .pd-product-slider .tile-image {
  display: block; }

.in-editor .carousel.edit-mode .carousel-wrapper {
  border: 0.625rem solid #ffdf5e;
  position: relative; }
  .in-editor .carousel.edit-mode .carousel-wrapper:before {
    content: "Carousel Edit Mode";
    padding: 0.3125rem 0.625rem 0.625rem;
    position: absolute;
    top: -0.4375rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
    background-color: #ffdf5e; }

/*PD Slider Einstein*/
.einstein-carousel .slick-slide .product-tile {
  margin: 0 0.625rem; }

/* Promotion Banner */
.top-promotion-banner-slider .hide-before-slider-init {
  display: none; }

.top-promotion-banner-slider .slick-slide {
  height: auto; }
  .top-promotion-banner-slider .slick-slide .hide-before-slider-init {
    display: block; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.storepage {
  width: 100%; }

.anchor {
  position: relative;
  display: block;
  top: -130px; }
  .cat-landing .anchor {
    top: -170px; }
